@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i);
body.gs18-HeaderFog:after{content:"";display:block;position:fixed;top:0;right:0;bottom:0;left:0;background:#000;z-index:1;opacity:.8}
.gs18-Header{position:absolute;z-index:2;top:0;left:0;right:0;margin:0 auto;box-sizing:border-box}
.gs18-Header.is-flyout{position:fixed;top:0;transition:.4s top}
.gs18-Header.is-flyout.is-hidden{top:-90px}
.gs18-Header--HasUtilityLinks.is-flyout{position:fixed;top:0;transition:.4s top}
.gs18-Header--HasUtilityLinks.is-flyout.is-hidden{top:-146px}
.gs18-Header--HasCookieAlert.is-flyout{position:fixed;top:0;transition:.4s top}
.gs18-Header--HasCookieAlert.is-flyout.is-hidden{top:-319px}
.gs18-Header--HasCookieAlert.gs18-Header--HasUtilityLinks.is-flyout.is-hidden{top:-375px}
.gs18-Header__Inner{display:-webkit-box;display:-ms-flexbox;display:flex;max-width:1380px;margin-left:auto;margin-right:auto;height:85px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;background-color:#fff}
.gs18-Header__Logo{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1;margin:8px 0 0;padding:0 16px;border-color:#e1e1e1;border-width:0 1px 0 0;border-style:solid;font-size:36px}
[dir=rtl] .gs18-Header__Logo{border-right-width:0;border-left-width:1px}
.gs18-Header__Logo img{width:84px;vertical-align:baseline}
.gs18-Header__Logo img,.gs18-Header__Logo img _:-ms-lang(x)::-ms-backdrop{height:100%}
.gs18-Header__Lang{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2;margin-right:10px;margin-left:16px;font-size:14px;font-weight:700}
[dir=rtl] .gs18-Header__Lang{margin-right:16px;margin-left:10px}
.gs18-Header__Global{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4;margin-left:0;height:100%}
.gs18-Header__GlobalLang{display:inline-block;font-size:14px;font-weight:700;text-decoration:none;color:#000;line-height:1.1;vertical-align:6px}
.gs18-Header__GlobalLang abbr{text-decoration:none}
.gs18-Header__GlobalLang:first-child{border-right:1px solid #000;padding-right:5px}
.gs18-Header__GlobalLang:hover{text-decoration:underline}
.gs18-Header__GlobalLink{display:inline-block;position:relative;text-indent:100%;white-space:nowrap;overflow:hidden;width:66px;height:100%}
.gs18-Header__GlobalLink::after,.gs18-Header__GlobalLink::before{position:absolute;right:0;top:0;content:"";display:inline-block;background:#fff no-repeat center;background-size:26px auto;width:100%;height:100%}
/* .gs18-Header__GlobalLink::before{background-image:url(../img/icon/Global-R.svg)} */
/* .gs18-Header__GlobalLink::after{background-image:url(../img/icon/Global.svg)} */
.gs18-Header__GlobalLink:active::after,.gs18-Header__GlobalLink:focus::after,.gs18-Header__GlobalLink:hover::after{opacity:0}
.gs18-Header__GlobalLink.is-current{background-color:#000}
.gs18-Header__GlobalLink.is-current::before{background-color:#000}
/* .gs18-Header__GlobalLink.is-current::after{background-image:url(../img/icon/Global-W.svg);background-color:#000} */
.gs18-Header--NoSearch .gs18-Header__Search{display:none}
.gs18-Header__Search{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex}
.gs18-Header__SearchInner{box-sizing:border-box;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5;background-color:#fff}
.gs18-Header__Search .gs18-Header__SearchInput{box-sizing:border-box}
/* .gs18-Header__Search .gs18-Header__SearchBtn{position:relative;display:block;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:84px;height:100%;border:0;border-left:1px solid #e0e0e0;background:url(../img/icon/Search-R.svg) no-repeat -100% -100%;cursor:pointer} */
[dir=rtl] .gs18-Header__Search .gs18-Header__SearchBtn{border-left-width:0;border-right:1px solid #e0e0e0}
.gs18-Header__Search .gs18-Header__SearchBtn::before{content:"";position:absolute;top:50%;left:11px;width:9px;height:9px;border-top:2px solid #000;border-left:2px solid #000;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg);font-weight:700}
[dir=rtl] .gs18-Header__Search .gs18-Header__SearchBtn::before{left:auto;right:11px;-webkit-transform:translateY(-50%) rotate(135deg);transform:translateY(-50%) rotate(135deg)}
.gs18-Header__Search .gs18-Header__SearchBtn>img{margin-top:1px}
.gs18-Header__Search .gs18-Header__SearchBtn:active,.gs18-Header__Search .gs18-Header__SearchBtn:focus,.gs18-Header__Search .gs18-Header__SearchBtn:hover{background-position:center center}
.gs18-Header__Search .gs18-Header__SearchBtn:active::before,.gs18-Header__Search .gs18-Header__SearchBtn:focus::before,.gs18-Header__Search .gs18-Header__SearchBtn:hover::before{border-color:#f50f10}
.gs18-Header__Search .gs18-Header__SearchBtn:active>img,.gs18-Header__Search .gs18-Header__SearchBtn:focus>img,.gs18-Header__Search .gs18-Header__SearchBtn:hover>img{opacity:0}
.gs18-Header__Search .gs18-SearchBox__Items,.gs18-Header__Search .mf_finder_searchBox_items{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.gs18-Header__Search .gs18-SearchBox__QueryInput,.gs18-Header__Search .mf_finder_searchBox_query_input{border:0;border-bottom:solid 2px #000;font-size:24px;font-style:italic}
.gs18-Header__Search .gs18-SearchBox__QueryInput:placeholder-shown,.gs18-Header__Search .mf_finder_searchBox_query_input:placeholder-shown{color:#bbb}
.gs18-Header__Search .gs18-SearchBox__QueryInput::-webkit-input-placeholder,.gs18-Header__Search .mf_finder_searchBox_query_input::-webkit-input-placeholder{color:#bbb}
.gs18-Header__Search .gs18-SearchBox__QueryInput:-moz-placeholder,.gs18-Header__Search .mf_finder_searchBox_query_input:-moz-placeholder{color:#bbb;opacity:1}
.gs18-Header__Search .gs18-SearchBox__QueryInput::-moz-placeholder,.gs18-Header__Search .mf_finder_searchBox_query_input::-moz-placeholder{color:#bbb;opacity:1}
.gs18-Header__Search .gs18-SearchBox__QueryInput:-ms-input-placeholder,.gs18-Header__Search .mf_finder_searchBox_query_input:-ms-input-placeholder{color:#bbb}
/* .gs18-Header__Search .gs18-SearchBox__Submit,.gs18-Header__Search .mf_finder_searchBox_submit{width:28px;height:28px;margin-left:14px;padding:0;border:0;cursor:pointer;background:url(../img/icon/Search-R.svg) no-repeat -100% -100%} */
[dir=rtl] .gs18-Header__Search .gs18-SearchBox__Submit,[dir=rtl] .gs18-Header__Search .mf_finder_searchBox_submit{margin-left:0;margin-right:14px}
/* .gs18-Header__Search .gs18-SearchBox__Submit>span,.gs18-Header__Search .mf_finder_searchBox_submit>span{display:block;height:100%;width:100%;background:url(../img/icon/Search.svg) no-repeat center center #fff;text-indent:-9999px;overflow:hidden;white-space:nowrap} */
.gs18-Header__Search .gs18-SearchBox__Submit:active,.gs18-Header__Search .gs18-SearchBox__Submit:focus,.gs18-Header__Search .gs18-SearchBox__Submit:hover,.gs18-Header__Search .mf_finder_searchBox_submit:active,.gs18-Header__Search .mf_finder_searchBox_submit:focus,.gs18-Header__Search .mf_finder_searchBox_submit:hover{background-position:center center}
.gs18-Header__Search .gs18-SearchBox__Submit:active>span,.gs18-Header__Search .gs18-SearchBox__Submit:focus>span,.gs18-Header__Search .gs18-SearchBox__Submit:hover>span,.gs18-Header__Search .mf_finder_searchBox_submit:active>span,.gs18-Header__Search .mf_finder_searchBox_submit:focus>span,.gs18-Header__Search .mf_finder_searchBox_submit:hover>span{opacity:0}
.gs18-Header__Search .mf_finder_searchBox_suggest_items{position:absolute;left:0;top:100%;margin:0;padding:0;width:100%;background-color:rgba(0,0,0,.8);list-style-type:none;z-index:5}
.gs18-Header__Search .mf_finder_searchBox_suggest_item>a{box-sizing:border-box;display:inline-block;width:100%;padding:15px 30px;text-decoration:none;color:#fff;font-size:24px;font-style:italic}
.gs18-Header__Search .mf_finder_searchBox_suggest_item_cur{background-color:rgba(80,80,80,.8)}
.gs18-Header__Hamburger{display:none}
.gs18-HeaderLang{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4}
.gs18-HeaderNav{margin:0 auto;-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
.gs18-HeaderNav__Menu{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin:0;padding:0;height:85px;list-style:none}
.gs18-HeaderNav__Item{transition:.4s opacity;height:100%}
.gs18-HeaderNav__Text{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 15px;height:100%;font-size:18px;font-weight:500;text-align:center;color:#000;text-decoration:none}
.gs18-HeaderNav__Text:link,.gs18-HeaderNav__Text:visited{color:#000;text-decoration:none}
.gs18-HeaderNav__Text.is-current,.gs18-HeaderNav__Text.is-open{background:#000;color:#fff}
.gs18-HeaderNav__Text.is-current:active,.gs18-HeaderNav__Text.is-current:focus,.gs18-HeaderNav__Text.is-current:hover,.gs18-HeaderNav__Text.is-open:active,.gs18-HeaderNav__Text.is-open:focus,.gs18-HeaderNav__Text.is-open:hover,.gs18-HeaderNav__Text:active,.gs18-HeaderNav__Text:focus,.gs18-HeaderNav__Text:hover{color:#f50f10}
.gs18-HeaderPulldown{display:none;overflow:hidden;position:absolute;left:0;top:85px;width:100%}
[dir=rtl] .gs18-HeaderPulldown{right:0;left:auto}
.gs18-HeaderPulldown__Inner{display:table;width:100%;box-sizing:border-box;min-height:270px;background-color:#f6f6f6}
.gs18-HeaderPulldown__Header{box-sizing:border-box;display:table-cell;width:34%;padding:75px 0 30px 30px}
[dir=rtl] .gs18-HeaderPulldown__Header{padding-right:30px;padding-left:0}
.gs18-HeaderPulldown__Title{margin:0 0 40px;font-size:36px;font-weight:700;line-height:1}
.gs18-HeaderPulldown__Title>a:active,.gs18-HeaderPulldown__Title>a:focus,.gs18-HeaderPulldown__Title>a:hover,.gs18-HeaderPulldown__Title>a:link,.gs18-HeaderPulldown__Title>a:visited{color:#000;text-decoration:none;cursor:default}
/* .gs18-HeaderPulldown__Gotop{box-sizing:border-box;display:table-cell;vertical-align:middle;padding:0 15px;width:280px;height:50px;background:url(../img/icon/ArrowNext.svg) no-repeat right 15px center;background-size:36px auto;border:solid 2px;color:#000;font-size:18px;font-weight:700;text-decoration:none;transition:.2s background,.2s border-color,.2s color} */
/* [dir=rtl] .gs18-HeaderPulldown__Gotop{background-image:url(../img/icon/ArrowBack.svg);background-position:left 15px center} */
.gs18-HeaderPulldown__Gotop:link,.gs18-HeaderPulldown__Gotop:visited{color:#000}
/* .gs18-HeaderPulldown__Gotop:active,.gs18-HeaderPulldown__Gotop:focus,.gs18-HeaderPulldown__Gotop:hover{background-image:url(../img/icon/ArrowNext-R.svg);background-position:right 7.5px center;background-color:#fff;border-color:#fff;color:#f50f10} */
/* [dir=rtl] .gs18-HeaderPulldown__Gotop:active,[dir=rtl] .gs18-HeaderPulldown__Gotop:focus,[dir=rtl] .gs18-HeaderPulldown__Gotop:hover{background-image:url(../img/icon/ArrowBack-R.svg);background-position:left 7.5px center} */
.gs18-HeaderPulldown__Back{display:none}
/* .gs18-HeaderPulldown__Close{position:absolute;top:30px;right:30px;z-index:3;background:url(../img/icon/Close-R.svg) no-repeat -100% -100%} */
[dir=rtl] .gs18-HeaderPulldown__Close{right:auto;left:30px}
.gs18-HeaderPulldown__Close img{vertical-align:top}
.gs18-HeaderPulldown__Close:active,.gs18-HeaderPulldown__Close:focus,.gs18-HeaderPulldown__Close:hover{background-position:top left}
.gs18-HeaderPulldown__Close:active img,.gs18-HeaderPulldown__Close:focus img,.gs18-HeaderPulldown__Close:hover img{opacity:0}
.gs18-HeaderPulldown__List{position:relative;list-style:none;display:table-cell;vertical-align:top;padding:70px 0 30px}
.gs18-HeaderPulldown__ListItem{font-size:18px;line-height:1}
.gs18-HeaderPulldown__ListItem>a,.gs18-HeaderPulldown__ListItem>span{box-sizing:border-box;position:relative;display:table;padding:.575em 0;white-space:nowrap;width:0%;color:#000}
.gs18-HeaderPulldown__ListItem>a{font-weight:500;text-decoration:none;transition:.5s width,.5s padding}
.gs18-HeaderPulldown__ListItem>a:not(:only-child){padding-right:16px}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a:not(:only-child){padding-right:0;padding-left:16px}
.gs18-HeaderPulldown__ListItem>a:not(:only-child)::after{content:"";position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%) rotate(-225deg);transform:translateY(-50%) rotate(-225deg);display:inline-block;margin:2px 3px 0 6px;width:5px;height:5px;vertical-align:bottom;border-top:2px solid #000;border-left:2px solid #000;font-weight:700}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a:not(:only-child)::after{margin-right:6px;margin-left:3px;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg);right:auto;left:0}
.gs18-HeaderPulldown__ListItem>a:link,.gs18-HeaderPulldown__ListItem>a:visited{color:#000}
.gs18-HeaderPulldown__Sublist{-webkit-transform:translateX(100%);transform:translateX(100%);transition:.5s transform;box-sizing:border-box;position:absolute;z-index:2;right:0;top:0;padding:0 0 0 30px;width:50%;min-height:100%;background-color:#e1e1e1}
[dir=rtl] .gs18-HeaderPulldown__Sublist{-webkit-transform:translateX(-100%);transform:translateX(-100%);right:auto;left:0;padding-right:30px;padding-left:0}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__Header{display:none}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__List{display:block}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem>a,.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem>span{width:100%;white-space:normal}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem>span{padding-top:10px}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List{padding:0 0 0 30px}
[dir=rtl] .gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List{padding-right:30px;padding-left:0}
.gs18-HeaderPulldownRegion{box-sizing:border-box;padding:75px 0 30px;width:100%;min-height:270px;background-color:#f5f5f5}
.gs18-HeaderPulldownRegion__Header{margin:0 30px 60px}
.gs18-HeaderPulldownRegion__Title{margin:0;font-size:64px;font-weight:700;line-height:1}
.gs18-HeaderPulldownRegion .gs18-Tile:nth-child(n){background-color:transparent}
.gs18-HeaderPulldownRegion .gs18-Tile:nth-child(n) .gs18-TileItem:nth-child(n){background-color:transparent}
.gs18-HeaderPulldownRegion .gs18-TileItem{margin-bottom:60px}
.gs18-HeaderPulldownRegion .gs18-TileItem__Head{padding-top:0;padding-bottom:15px}
.gs18-HeaderPulldownRegion .gs18-TileItem__Body{padding-top:0;padding-bottom:0}
.gs18-HeaderPulldownRegion .gs18-HeadingLv2{font-size:20px;line-height:1.4}
.gs18-HeaderPulldownRegion .gs18-LineTable{line-height:1.1}
.gs18-HeaderPulldownRegion .gs18-LinkListItem{margin-top:5px;font-size:inherit}
.gs18-RegionList{margin:0 0 60px;padding:0 30px;box-sizing:border-box}
.gs18-RegionList__Title{margin:0 0 15px;font-size:20px;font-weight:700}
.gs18-RegionList__List{list-style:none;margin:0;padding-left:0;border-top:1px solid #989898}
[dir=rtl] .gs18-RegionList__List{padding-right:0}
.gs18-RegionList__Item{display:-webkit-box;display:-ms-flexbox;display:flex;padding:15px 0 12px;border-bottom:1px solid #989898;line-height:1.1}
.gs18-RegionList__Country{display:block;width:150px;-webkit-box-flex:0;-ms-flex:0 0 150px;flex:0 0 150px;font-size:16px}
.gs18-RegionList__Language{list-style:none;margin:0;padding-left:0;-webkit-box-flex:1;-ms-flex:1 0;flex:1 0}
[dir=rtl] .gs18-RegionList__Language{padding-right:0}
.gs18-RegionList__Language>li{float:left;width:50%;margin-bottom:5px;font-size:16px;font-weight:500}
[dir=rtl] .gs18-RegionList__Language>li{float:right}
.gs18-RegionList__Language>li>a{display:block;position:relative;padding-left:24px;text-decoration:none;color:#000;word-break:break-word}
[dir=rtl] .gs18-RegionList__Language>li>a{padding-right:24px;padding-left:0}
.gs18-RegionList__Language>li>a::before{content:"";position:absolute;left:0;top:0;margin-right:12px;width:8px;height:8px;border-top:2px solid #000;border-left:2px solid #000;vertical-align:middle;-webkit-transform:translateY(40%) rotate(-225deg);transform:translateY(40%) rotate(-225deg)}
[dir=rtl] .gs18-RegionList__Language>li>a::before{right:0;left:auto;-webkit-transform:translateY(40%) rotate(-45deg);transform:translateY(40%) rotate(-45deg);margin-right:auto;margin-left:12px}
.gs18-RegionList__Language>li>a:active,.gs18-RegionList__Language>li>a:focus,.gs18-RegionList__Language>li>a:hover{color:#f50f10}
.gs18-RegionList__Language>li>a:active::before,.gs18-RegionList__Language>li>a:focus::before,.gs18-RegionList__Language>li>a:hover::before{border-color:#f50f10}
.gs18-CookieAlert{background:#f5f5f5;display:none;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
body.gs18-HasCookieAlert .gs18-CookieAlert{display:-webkit-box;display:-ms-flexbox;display:flex}
.gs18-CookieAlert__Inner{width:100%}
.gs18-CookieAlert__Title{margin:0;font-size:18px;line-height:1.4}
.gs18-CookieAlert__Message{margin:10px 0}
.gs18-CookieAlert__LearnMore{margin:20px 0 0}
.gs18-CookieAlert__Button{margin:0}
.gs18-Header{font-family:Roboto,sans-serif;font-weight:300;line-height:1.4;position:relative}
.gs18-Header--NoSearch .gs18-Header__Search{display:none}
.gs18-Header__Search{height:85px}
.gs18-HeaderCustomUtility{-webkit-box-ordinal-group:5;-ms-flex-order:4;order:4;height:100%}
.gs18-HeaderCustomUtilityLinks{margin:0;padding:0;display:-webkit-box;display:-ms-flexbox;display:flex;height:100%;font-size:15px;list-style:none}
.gs18-HeaderCustomUtilityLinks__Item{padding:0 10px}
.gs18-HeaderCustomUtilityLinks__Item a{padding-left:30px;position:relative;display:inline-block;color:#000;text-decoration:none}
.gs18-HeaderCustomUtilityLinks__Item a:link,.gs18-HeaderCustomUtilityLinks__Item a:visited{color:#000;text-decoration:none}
.gs18-HeaderCustomUtilityLinks__Item a:active,.gs18-HeaderCustomUtilityLinks__Item a:focus,.gs18-HeaderCustomUtilityLinks__Item a:hover{color:#f50f10}
.gs18-HeaderCustomUtilityLinks__Item img{position:absolute;top:-.2rem;left:0;width:26px;height:26px}
.gs18-Header--withme .gs18-Header__Logo{border-right-width:0}
.gs18-Header--withme .gs18-Header__Logo img{height:36px;width:auto}
.gs18-Header--withme .gs18-Header__Lang{margin:0 10px 0 16px;-webkit-box-flex:2;-ms-flex-positive:2;flex-grow:2;line-height:1.2;font-weight:500;-webkit-font-smoothing:antialiased}
.gs18-Footer{background-color:#dbdbdb;font-size:18px}
.gs18-Footer__Inner{position:relative}
.gs18-Footer__Global{margin:0;padding:1.2em;line-height:1.1;font-size:18px;font-weight:500;text-align:center;background-color:#e0e0e0}
/* .gs18-Footer__Global>a{color:#000;text-decoration:none;padding:5px 36px 5px 0;background:url(../img/icon/Global.svg) no-repeat right center,url(../img/icon/Global-R.svg) no-repeat -100% -100%;background-size:20px auto} */
.gs18-Footer__Global>a:link,.gs18-Footer__Global>a:visited{color:#000}
.gs18-Footer__Global>a:active,.gs18-Footer__Global>a:focus,.gs18-Footer__Global>a:hover{color:#f50f10;background-position:-100% -100%,right center}
.gs18-Footer__List{margin-top:22px;list-style:none;padding:1px 0 0}
.gs18-Footer__List>li{display:inline-block;margin-bottom:10px}
.gs18-Footer__List>li>a{position:relative;color:inherit;font-size:18px;font-weight:500;text-decoration:none;padding-left:22px}
[dir=rtl] .gs18-Footer__List>li>a{padding-right:22px;padding-left:0}
.gs18-Footer__List>li>a::before{content:"";position:absolute;top:50%;left:0;width:9px;height:9px;border-top:2px solid #000;border-left:2px solid #000;-webkit-transform:translateY(-50%) rotate(-225deg);transform:translateY(-50%) rotate(-225deg);font-weight:700}
[dir=rtl] .gs18-Footer__List>li>a::before{right:0;left:auto;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg)}
.gs18-Footer__List>li>a:link,.gs18-Footer__List>li>a:visited{color:#000}
.gs18-Footer__List>li>a:active,.gs18-Footer__List>li>a:focus,.gs18-Footer__List>li>a:hover{color:#f50f10}
.gs18-Footer__List>li>a:active:before,.gs18-Footer__List>li>a:focus:before,.gs18-Footer__List>li>a:hover:before{border-color:#f50f10}
.gs18-Footer__Links{padding:15px 30px;width:100%}
.gs18-Footer__Legal{padding:0;list-style:none;font-size:18px}
.gs18-Footer__Legal>li{display:inline-block;padding-right:.3em;line-height:1.1}
[dir=rtl] .gs18-Footer__Legal>li{padding-right:0;padding-left:.3em}
.gs18-Footer__Legal>li:not(:first-child){padding-left:.5em;border-left:1px solid #000}
[dir=rtl] .gs18-Footer__Legal>li:not(:first-child){padding-right:.5em;padding-left:0;border-right:1px solid #000;border-left:0}
.gs18-Footer__Legal>li>a{color:inherit;text-decoration:none}
.gs18-Footer__Legal>li>a:link,.gs18-Footer__Legal>li>a:visited{color:#000}
.gs18-Footer__Legal>li>a:active,.gs18-Footer__Legal>li>a:focus,.gs18-Footer__Legal>li>a:hover{color:#f50f10}
.gs18-Footer__Legal>li>a:active img,.gs18-Footer__Legal>li>a:focus img,.gs18-Footer__Legal>li>a:hover img{opacity:0}
.gs18-Footer__Legal>li>a img{margin-top:-5px;margin-bottom:-5px;position:relative;top:-.1em;vertical-align:middle;background:#dbdbdb}
/* .gs18-Footer__LegalExternal{background:url(../img/icon/External-R.svg) no-repeat 0 50%} */
[dir=rtl] .gs18-Footer__LegalExternal{background-position:100% 50%}
.gs18-Footer__SNS{margin:0 0 20px;padding:0;list-style:none;line-height:20px;vertical-align:top}
.gs18-Footer__SNS>li{display:inline-block;margin-top:20px}
.gs18-Footer__SNS>li:not(:last-child){margin-right:16px}
[dir=rtl] .gs18-Footer__SNS>li:not(:last-child){margin-right:auto;margin-left:16px}
.gs18-Footer__SNS>li a{display:block;width:32px;min-width:32px}
.gs18-Footer__SNS>li img{width:32px;height:auto;vertical-align:middle}
.gs18-Footer__SNSLinkedin{height:32px!important;min-height:32px!important;width:auto!important;min-width:auto!important}
.gs18-Footer__SNSLinkedin img{width:auto!important;height:32px!important}
.gs18-Footer__SNSLinkedin-r{height:32px!important;min-height:32px!important;width:auto!important;min-width:auto!important}
.gs18-Footer__SNSLinkedin-r img{width:auto!important;height:32px!important}
.gs18-Footer__SNSWechat{position:relative}
.gs18-Footer__SNSWechat__QRBox{display:none;position:absolute;top:-40px;left:48px;z-index:999;width:200px;background-color:#fff;box-shadow:0 0 16px rgba(0,0,0,.5)}
.gs18-Footer__SNSWechat .gs18-Footer__SNSWechat__QRBox img{margin:0;width:inherit;height:auto}
.gs18-Footer__SNSWechat:focus .gs18-Footer__SNSWechat__QRBox img,.gs18-Footer__SNSWechat:hover .gs18-Footer__SNSWechat__QRBox img{opacity:1}
.gs18-Footer__Copyright{margin-right:auto;font-size:18px}
[dir=rtl] .gs18-Footer__Copyright{margin-right:0;margin-left:auto}
.gs18-Footer__ExtentionInner{margin:0 auto;max-width:1380px}
.gs18-Footer__ExtentionInner a:link,.gs18-Footer__ExtentionInner a:visited{color:#000;text-decoration:none}
.gs18-Footer__ExtentionInner a[href]:active,.gs18-Footer__ExtentionInner a[href]:focus,.gs18-Footer__ExtentionInner a[href]:hover{color:#f50f10}
.gs18-GoTop{position:fixed;bottom:0;right:0;margin:0;z-index:1}
[dir=rtl] .gs18-GoTop{right:auto;left:0}
.gs18-GoTop>a{position:relative;display:block;width:48px;height:48px;background-color:#000;color:#fff}
.gs18-GoTop>a::before{content:"";position:absolute;top:55%;left:50%;width:8px;height:8px;border-top:2px solid #fff;border-left:2px solid #fff;-webkit-transform:translate(-50%,-50%) rotate(45deg);transform:translate(-50%,-50%) rotate(45deg)}
[dir=rtl] .gs18-GoTop>a::before{right:50%;left:auto}
.gs18-HeadingLv2{margin:60px 0 0;line-height:1.2;font-weight:700;word-wrap:break-word;overflow-wrap:break-word}
.gs18-HeadingLv2--Icon{display:table;width:100%}
.gs18-HeadingLv2--Icon .gs18-HeadingLv2__IconCol{padding-right:20px;display:table-cell;vertical-align:top;box-sizing:content-box}
.gs18-HeadingLv2--Icon .gs18-HeadingLv2__IconCol>img{max-width:100%}
.gs18-HeadingLv2--Icon .gs18-HeadingLv2__HeadingCol{padding-top:19px;border-color:#e0e0e0;border-style:solid;border-width:1px 0 0;display:table-cell;vertical-align:top}
:lang(ar) .gs18-HeadingLv2--Icon .gs18-HeadingLv2__IconCol{padding-right:0;padding-left:20px}
.gs18-HeadingLv2--Tag{margin-top:50px}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2__TagCol{margin-bottom:5px;display:block}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag{padding:2px 4px;border-color:#000;border-style:solid;border-width:1px;display:inline-block;line-height:1.2;background-color:#fff}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag--White{border-color:#000;color:#000;background-color:#fff}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag--Black{border-color:#000;color:#fff;background-color:#000}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag--Gray{border-color:#757575;color:#fff;background-color:#757575}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2__HeadingCol{display:block}
.gs18-HeadingLv2--Shoulder{margin-top:50px}
.gs18-HeadingLv2--Shoulder .gs18-HeadingLv2__ShoulderCol{margin:0 0 5px;display:block;line-height:1.2;font-size:1rem}
.gs18-HeadingLv2--Shoulder .gs18-HeadingLv2__HeadingCol{display:block}
.gs18-HeadingLv2Unit__SubTextCol{margin-top:5px;font-size:14rem}
.gs18-HeadingLv2Unit__SubTextCol .gs18-Text{margin-top:0}
.gs18-HeadingLv2Unit__SubTextCol .gs18-Date{margin-top:0;line-height:1.2;color:#757575}
.gs18-HeadingLv2--TextShadow{text-shadow:0 0 15px rgba(0,0,0,.8)}
.gs18-HeadingLv2Unit__LinkCol{display:none;text-align:right}
.gs18-HeadingLv2Unit__LinkCol .gs18-LinkList,.gs18-HeadingLv2Unit__LinkCol .gs18-LinkListItem{display:inline-block}
.gs18-Footer{font-family:Roboto,sans-serif;font-weight:300;line-height:1.4}
.gs18-Footer__Copyright,.gs18-Footer__Legal{font-weight:300}
@media only screen and (min-width:1380px){
.gs18-Header,.gs18-Header _:-ms-lang(x)::-ms-backdrop{width:1380px}
.gs18-Header--HasUtilityLinks,.gs18-Header--HasUtilityLinks _:-ms-lang(x)::-ms-backdrop{width:auto}
.gs18-Header--HasCookieAlert,.gs18-Header--HasCookieAlert _:-ms-lang(x)::-ms-backdrop{width:auto}
.gs18-Header--HasCookieAlert.gs18-Header--HasUtilityLinks,.gs18-Header--HasCookieAlert.gs18-Header--HasUtilityLinks _:-ms-lang(x)::-ms-backdrop{width:auto}
}
@media print{
.gs18-Header.is-flyout{position:absolute}
.gs18-Header.is-flyout.is-hidden{top:0}
.gs18-Header__GlobalLink.is-open{background-color:#000}
.gs18-Header__GlobalLink.is-open::before{background-color:#000}
/* .gs18-Header__GlobalLink.is-open::after{background-image:url(../img/icon/Global-W.svg);background-color:#000;opacity:1} */
.gs18-Header__GlobalLink.is-open:active::after,.gs18-Header__GlobalLink.is-open:focus::after,.gs18-Header__GlobalLink.is-open:hover::after{opacity:0}
html{font-size:112.5%}
body{width:1440px;-webkit-transform:scale(.75);transform:scale(.75);-webkit-transform-origin:0 0;transform-origin:0 0}
.gs18-Header.gs18-Header--HasUtilityLinks{padding-top:56px}
.gs18-Header.gs18-Header--HasCookieAlert{padding-top:0}
.gs18-Header.has-no-searchbox .gs18-Header__Inner{padding-right:0}
.gs18-Header.has-no-searchbox .gs18-Header__Search{display:none}
.gs18-Header .gs18-Header__SearchBtn>img{margin-left:1px}
.gs18-Header__Inner{padding-right:0}
.gs18-Header__Inner.is-search-open .gs18-HeaderLang,.gs18-Header__Inner.is-search-open .gs18-HeaderNav__Item,.gs18-Header__Inner.is-search-open .gs18-Header__Global{display:none}
.gs18-Header__Logo>img{width:110px}
.gs18-Header__Global{margin-left:0}
.gs18-Header__GlobalLink{width:61px}
.gs18-Header__Search{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5;height:100%;z-index:1;right:0}
.gs18-Header__Search.is-open .gs18-Header__SearchInput{width:604px;border-left:1px solid #e0e0e0}
[dir=rtl] .gs18-Header__Search.is-open .gs18-Header__SearchInput{border-left-width:0;border-right:1px solid #e0e0e0}
/* .gs18-Header__Search.is-open .gs18-Header__SearchInner>button{background:url(../img/icon/Close-R.svg) no-repeat center center} */
.gs18-Header__Search.is-open .gs18-Header__SearchInner>button>img{opacity:0;width:0;overflow:hidden}
.gs18-Header__Search.is-open .gs18-Header__SearchInner>button::before{content:none}
/* .gs18-Header__Search.is-open .gs18-Header__SearchInner>button::after{content:url(../img/icon/Close.svg);display:block;margin-top:5px;background:#fff} */
.gs18-Header__Search.is-open .gs18-Header__SearchInner>button:active::after,.gs18-Header__Search.is-open .gs18-Header__SearchInner>button:focus::after,.gs18-Header__Search.is-open .gs18-Header__SearchInner>button:hover::after{opacity:0}
.is-search-open .gs18-Header__Search .gs18-Header__SearchInput{visibility:visible}
.gs18-Header__Search .gs18-Header__SearchInput{visibility:hidden;width:0;transition:.5s width;overflow:hidden;box-sizing:border-box;height:100%}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox{padding:30px 30px 20px 30px}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input{width:500px;border:0;border-bottom:solid 2px #000;font-size:24px;font-style:italic}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput:placeholder-shown,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input:placeholder-shown{color:#888}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput::-webkit-input-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input::-webkit-input-placeholder{color:#888}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput:-moz-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input:-moz-placeholder{color:#888;opacity:1}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput::-moz-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input::-moz-placeholder{color:#888;opacity:1}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput:-ms-input-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input:-ms-input-placeholder{color:#888}
.gs18-HeaderLang{height:100%}
.gs18-HeaderLang .gs18-HeaderLang__Lang{font-weight:500}
.gs18-HeaderLang .gs18-HeaderLang__Lang>abbr{text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__Stage{position:relative;height:100%}
.gs18-HeaderLang .gs18-HeaderLang__List{position:absolute;display:none;width:215px;margin:0;padding:0;left:0;overflow:hidden;background:#000;list-style:none;font-size:18px;font-weight:500}
[dir=rtl] .gs18-HeaderLang .gs18-HeaderLang__List{left:auto;right:0}
.gs18-HeaderLang .gs18-HeaderLang__List>li{border-top:solid 1px #272727}
.gs18-HeaderLang .gs18-HeaderLang__List>li>a{display:block;padding:15px;color:#fff;text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__List>li>a:active,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:focus,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:hover{color:#f50f10}
.gs18-HeaderLang[data-js-gs18-lang-switch="2"] .gs18-HeaderLang__List{display:none}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double{display:-webkit-box;display:-ms-flexbox;display:flex;font-size:14px}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang{padding:32px 0;box-sizing:border-box;width:48px;height:85px;text-align:center;color:#000;text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:first-child{position:relative;margin-right:1px}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:first-child:before{content:'';display:block;position:absolute;top:28px;right:-1px;height:26px;border-right:1px solid #e0e0e0}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:active,.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang-Current>abbr{position:relative}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang-Current>abbr:before{content:'';position:absolute;bottom:-4px;width:100%;border-bottom:2px solid #000}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple{font-size:14px}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang{display:block;box-sizing:border-box;padding:34px 20px 32px 0;width:69px;height:85px;text-align:center;color:#000;text-decoration:none;position:relative}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:before{content:'';display:block;position:absolute;top:39px;right:20px;width:0;height:0;border-style:solid;border-width:7px 4px 0 4px;border-color:#000 transparent transparent transparent}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active:before,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus:before,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover:before{border-top-color:#f50f10}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__List{display:block}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang{background:#000;color:#fff}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang::before{-webkit-transform:rotate(180deg);transform:rotate(180deg);border-top-color:#fff}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active:before,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus:before,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover:before{border-top-color:#f50f10}
.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text.is-current{background:#fff;color:#000}
.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text.is-open{background:#000;color:#fff}
.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text:active,.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text:focus,.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text:hover{color:red}
.gs18-HeaderNav__Text.is-current,.gs18-HeaderNav__Text.is-open{background:#000;color:#fff}
.gs18-HeaderNav__Item--About .gs18-HeaderPulldown__List,.gs18-HeaderNav__Item--Sustainability .gs18-HeaderPulldown__List{height:380px}
.gs18-HeaderNav__Item--Height600 .gs18-HeaderPulldown__List{height:500px}
.gs18-HeaderNav__Item--Height500 .gs18-HeaderPulldown__List{height:400px}
.gs18-HeaderNav__Item--Height400 .gs18-HeaderPulldown__List{height:300px}
.gs18-HeaderNav__Item--Height300 .gs18-HeaderPulldown__List{height:200px}
.gs18-HeaderNavUtilityLinks{margin:0 auto;max-width:1920px;position:absolute;background:#e0e0e0;height:56px;top:0;left:0;right:0}
.gs18-Header--HasCookieAlert .gs18-HeaderNavUtilityLinks{top:234px}
.gs18-HeaderNavUtilityLinks__Inner{max-width:1380px;margin:0 auto;padding:0 15px;height:100%;list-style:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.gs18-HeaderNavUtilityLinks__Item{margin-left:30px}
[dir=rtl] .gs18-HeaderNavUtilityLinks__Item{margin-left:0;margin-right:30px}
body.gs18-HasUtilityLinks{padding-top:56px}
.gs18-HeaderPulldown.is-open{display:block}
.gs18-Header--HasUtilityLinks .gs18-HeaderPulldown{top:141px}
.gs18-Header--HasCookieAlert .gs18-HeaderPulldown{top:319px}
.gs18-Header--HasUtilityLinks.gs18-Header--HasCookieAlert .gs18-HeaderPulldown{top:375px}
.gs18-HeaderPulldown__Inner{max-width:1380px;margin-right:auto;margin-left:auto;position:relative;overflow:hidden}
.gs18-HeaderPulldown__Sublist.is-open{-webkit-transform:translateX(0);transform:translateX(0)}
.gs18-HeaderPulldown__Sublist--Overflow{height:100%;overflow:hidden}
.gs18-HeaderPulldown__Sublist--Overflow:after,.gs18-HeaderPulldown__Sublist--Overflow:before{content:'';display:block;margin-right:30px;width:calc(100% - 30px);height:15px;position:absolute;left:0}
.gs18-HeaderPulldown__Sublist--Overflow:before{-webkit-transform:rotate(180deg);transform:rotate(180deg);top:70px;background-image:linear-gradient(-180deg,rgba(0,0,0,0) 1%,rgba(0,0,0,.08) 80%)}
.gs18-HeaderPulldown__Sublist--Overflow:after{bottom:0;background-image:linear-gradient(-180deg,rgba(0,0,0,0) 1%,rgba(0,0,0,.1) 80%)}
.gs18-HeaderPulldown__Sublist--Overflow .gs18-HeaderPulldown__List{margin:70px 30px 0 0;padding:0;height:calc(100% - 70px);overflow-y:scroll;z-index:1;scrollbar-base-color:#757575}
.gs18-HeaderPulldown__Sublist--Overflow .gs18-HeaderPulldown__ListItem{border-right:#ccc solid 1px}
.gs18-HeaderPulldown__ListItem>a.is-open{width:51%;padding-left:14px;background-color:#e1e1e1;color:#000}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a.is-open{padding-left:16px;padding-right:14px}
.gs18-HeaderPulldown__ListItem>a.is-open::after{right:15px}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a.is-open::after{right:auto;left:15px}
.gs18-HeaderPulldown__ListItem>a.is-open:active,.gs18-HeaderPulldown__ListItem>a.is-open:focus,.gs18-HeaderPulldown__ListItem>a.is-open:hover,.gs18-HeaderPulldown__ListItem>a:active,.gs18-HeaderPulldown__ListItem>a:focus,.gs18-HeaderPulldown__ListItem>a:hover{color:#f50f10}
.gs18-HeaderPulldown__ListItem>a.is-open:active:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a.is-open:focus:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a.is-open:hover:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a:active:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a:focus:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a:hover:not(:only-child)::after{border-color:#f50f10}
.gs18-HeaderPulldownRegion{max-width:1380px}
.gs18-HeaderPulldownRegion .gs18-TileItem.gs18-u-Size1of3{margin-bottom:0}
.gs18-RegionListWrap{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.gs18-RegionListWrap .gs18-RegionList{-webkit-box-flex:0;-ms-flex:0 0 33.3%;flex:0 0 33.3%;margin-bottom:0}
.gs18-CookieAlert{height:234px}
.gs18-Header--HasUtilityLinks .gs18-CookieAlert{margin-bottom:56px}
.gs18-CookieAlert__Inner{margin:0 auto;padding:0 30px;max-width:1380px;width:100%}
body.gs18-HasCookieAlert{padding-top:234px}
body.gs18-HasCookieAlert.gs18-HasUtilityLinks{padding-top:290px}
.gs18-HeadingLv2{font-size:1.56rem}
.gs18-HeadingLv2--Catch{font-size:3.56rem}
.gs18-HeadingLv2--Icon .gs18-HeadingLv2__IconCol{width:70px}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag{font-size:.78rem}
.gs18-Footer__Inner{padding:0 30px 30px;height:260px;max-width:1380px;margin:0 auto}
.gs18-Footer__Title{margin-top:0;padding-top:60px}
.gs18-Footer__List{width:680px;display:inline-block}
.gs18-Footer__List>li{margin-bottom:0;display:inline-block}
.gs18-Footer__List>li:nth-child(odd){margin-right:60px}
.gs18-Footer__List>li>a{position:relative;color:inherit;font-size:18px;font-weight:500;text-decoration:none;padding-left:22px}
.gs18-Footer__List>li>a::before{content:"";position:absolute;top:50%;left:0;width:9px;height:9px;border-top:2px solid #000;border-left:2px solid #000;-webkit-transform:translateY(-50%) rotate(-225deg);transform:translateY(-50%) rotate(-225deg);font-weight:700}
[dir=rtl] .gs18-Footer__List>li>a::before{right:0;left:auto;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg)}
.gs18-Footer__Links{position:absolute;left:0;bottom:0;box-sizing:border-box;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}
[dir=rtl] .gs18-Footer__Links{right:0;left:auto}
.gs18-Footer__SNS{margin-right:20px;display:inline-block}
[dir=rtl] .gs18-Footer__SNS{margin-right:auto;margin-left:20px}
.gs18-Footer__Legal{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
.gs18-Footer__Copyright{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
.gs18-Footer__ExtentionInner{padding:0 30px 30px}
}
@media only screen and (min-width:1920px){
.gs18-Header--HasUtilityLinks,.gs18-Header--HasUtilityLinks _:-ms-lang(x)::-ms-backdrop{width:1920px}
.gs18-Header--HasCookieAlert.gs18-Header--HasUtilityLinks,.gs18-Header--HasCookieAlert.gs18-Header--HasUtilityLinks _:-ms-lang(x)::-ms-backdrop{width:1920px}
}
@media only screen and (min-width:981px){
.gs18-Header__GlobalLink.is-open{background-color:#000}
.gs18-Header__GlobalLink.is-open::before{background-color:#000}
/* .gs18-Header__GlobalLink.is-open::after{background-image:url(../img/icon/Global-W.svg);background-color:#000;opacity:1} */
.gs18-Header__GlobalLink.is-open:active::after,.gs18-Header__GlobalLink.is-open:focus::after,.gs18-Header__GlobalLink.is-open:hover::after{opacity:0}
.gs18-Header.gs18-Header--HasUtilityLinks{padding-top:56px}
.gs18-Header.gs18-Header--HasCookieAlert{padding-top:0}
.gs18-Header.has-no-searchbox .gs18-Header__Inner{padding-right:0}
.gs18-Header.has-no-searchbox .gs18-Header__Search{display:none}
.gs18-Header .gs18-Header__SearchBtn>img{margin-left:1px}
.gs18-Header__Inner{padding-right:0}
.gs18-Header__Inner.is-search-open .gs18-HeaderLang,.gs18-Header__Inner.is-search-open .gs18-HeaderNav__Item,.gs18-Header__Inner.is-search-open .gs18-Header__Global{display:none}
.gs18-Header__Logo>img{width:110px}
.gs18-Header__Global{margin-left:0}
.gs18-Header__GlobalLink{width:61px}
.gs18-Header__Search{-webkit-box-ordinal-group:6;-ms-flex-order:5;order:5;height:100%;z-index:1;right:0}
.gs18-Header__Search.is-open .gs18-Header__SearchInput{width:604px;border-left:1px solid #e0e0e0}
[dir=rtl] .gs18-Header__Search.is-open .gs18-Header__SearchInput{border-left-width:0;border-right:1px solid #e0e0e0}
/* .gs18-Header__Search.is-open .gs18-Header__SearchInner>button{background:url(../img/icon/Close-R.svg) no-repeat center center} */
.gs18-Header__Search.is-open .gs18-Header__SearchInner>button>img{opacity:0;width:0;overflow:hidden}
.gs18-Header__Search.is-open .gs18-Header__SearchInner>button::before{content:none}
/* .gs18-Header__Search.is-open .gs18-Header__SearchInner>button::after{content:url(../img/icon/Close.svg);display:block;margin-top:5px;background:#fff} */
.gs18-Header__Search.is-open .gs18-Header__SearchInner>button:active::after,.gs18-Header__Search.is-open .gs18-Header__SearchInner>button:focus::after,.gs18-Header__Search.is-open .gs18-Header__SearchInner>button:hover::after{opacity:0}
.is-search-open .gs18-Header__Search .gs18-Header__SearchInput{visibility:visible}
.gs18-Header__Search .gs18-Header__SearchInput{visibility:hidden;width:0;transition:.5s width;overflow:hidden;box-sizing:border-box;height:100%}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox{padding:30px 30px 20px 30px}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input{width:500px;border:0;border-bottom:solid 2px #000;font-size:24px;font-style:italic}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput:placeholder-shown,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input:placeholder-shown{color:#888}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput::-webkit-input-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input::-webkit-input-placeholder{color:#888}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput:-moz-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input:-moz-placeholder{color:#888;opacity:1}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput::-moz-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input::-moz-placeholder{color:#888;opacity:1}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput:-ms-input-placeholder,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input:-ms-input-placeholder{color:#888}
.gs18-HeaderLang{height:100%}
.gs18-HeaderLang .gs18-HeaderLang__Lang{font-weight:500}
.gs18-HeaderLang .gs18-HeaderLang__Lang>abbr{text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__Stage{position:relative;height:100%}
.gs18-HeaderLang .gs18-HeaderLang__List{position:absolute;display:none;width:215px;margin:0;padding:0;left:0;overflow:hidden;background:#000;list-style:none;font-size:18px;font-weight:500}
[dir=rtl] .gs18-HeaderLang .gs18-HeaderLang__List{left:auto;right:0}
.gs18-HeaderLang .gs18-HeaderLang__List>li{border-top:solid 1px #272727}
.gs18-HeaderLang .gs18-HeaderLang__List>li>a{display:block;padding:15px;color:#fff;text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__List>li>a:active,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:focus,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:hover{color:#f50f10}
.gs18-HeaderLang[data-js-gs18-lang-switch="2"] .gs18-HeaderLang__List{display:none}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double{display:-webkit-box;display:-ms-flexbox;display:flex;font-size:14px}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang{padding:32px 0;box-sizing:border-box;width:48px;height:85px;text-align:center;color:#000;text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:first-child{position:relative;margin-right:1px}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:first-child:before{content:'';display:block;position:absolute;top:28px;right:-1px;height:26px;border-right:1px solid #e0e0e0}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:active,.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang-Current>abbr{position:relative}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang-Current>abbr:before{content:'';position:absolute;bottom:-4px;width:100%;border-bottom:2px solid #000}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple{font-size:14px}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang{display:block;box-sizing:border-box;padding:34px 20px 32px 0;width:69px;height:85px;text-align:center;color:#000;text-decoration:none;position:relative}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:before{content:'';display:block;position:absolute;top:39px;right:20px;width:0;height:0;border-style:solid;border-width:7px 4px 0 4px;border-color:#000 transparent transparent transparent}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active:before,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus:before,.gs18-HeaderLang .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover:before{border-top-color:#f50f10}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__List{display:block}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang{background:#000;color:#fff}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang::before{-webkit-transform:rotate(180deg);transform:rotate(180deg);border-top-color:#fff}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:active:before,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:focus:before,.gs18-HeaderLang.is-open .gs18-HeaderLang__Stage--Multiple .gs18-HeaderLang__Lang:hover:before{border-top-color:#f50f10}
.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text.is-current{background:#fff;color:#000}
.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text.is-open{background:#000;color:#fff}
.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text:active,.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text:focus,.gs18-HeaderNav.is-nav-open .gs18-HeaderNav__Text:hover{color:red}
.gs18-HeaderNav__Text.is-current,.gs18-HeaderNav__Text.is-open{background:#000;color:#fff}
.gs18-HeaderNav__Item--About .gs18-HeaderPulldown__List,.gs18-HeaderNav__Item--Sustainability .gs18-HeaderPulldown__List{height:380px}
.gs18-HeaderNav__Item--Height600 .gs18-HeaderPulldown__List{height:500px}
.gs18-HeaderNav__Item--Height500 .gs18-HeaderPulldown__List{height:400px}
.gs18-HeaderNav__Item--Height400 .gs18-HeaderPulldown__List{height:300px}
.gs18-HeaderNav__Item--Height300 .gs18-HeaderPulldown__List{height:200px}
.gs18-HeaderNavUtilityLinks{margin:0 auto;max-width:1920px;position:absolute;background:#e0e0e0;height:56px;top:0;left:0;right:0}
.gs18-Header--HasCookieAlert .gs18-HeaderNavUtilityLinks{top:234px}
.gs18-HeaderNavUtilityLinks__Inner{max-width:1380px;margin:0 auto;padding:0 15px;height:100%;list-style:none;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.gs18-HeaderNavUtilityLinks__Item{margin-left:30px}
[dir=rtl] .gs18-HeaderNavUtilityLinks__Item{margin-left:0;margin-right:30px}
body.gs18-HasUtilityLinks{padding-top:56px}
.gs18-HeaderPulldown.is-open{display:block}
.gs18-Header--HasUtilityLinks .gs18-HeaderPulldown{top:141px}
.gs18-Header--HasCookieAlert .gs18-HeaderPulldown{top:319px}
.gs18-Header--HasUtilityLinks.gs18-Header--HasCookieAlert .gs18-HeaderPulldown{top:375px}
.gs18-HeaderPulldown__Inner{max-width:1380px;margin-right:auto;margin-left:auto;position:relative;overflow:hidden}
.gs18-HeaderPulldown__Sublist.is-open{-webkit-transform:translateX(0);transform:translateX(0)}
.gs18-HeaderPulldown__Sublist--Overflow{height:100%;overflow:hidden}
.gs18-HeaderPulldown__Sublist--Overflow:after,.gs18-HeaderPulldown__Sublist--Overflow:before{content:'';display:block;margin-right:30px;width:calc(100% - 30px);height:15px;position:absolute;left:0}
.gs18-HeaderPulldown__Sublist--Overflow:before{-webkit-transform:rotate(180deg);transform:rotate(180deg);top:70px;background-image:linear-gradient(-180deg,rgba(0,0,0,0) 1%,rgba(0,0,0,.08) 80%)}
.gs18-HeaderPulldown__Sublist--Overflow:after{bottom:0;background-image:linear-gradient(-180deg,rgba(0,0,0,0) 1%,rgba(0,0,0,.1) 80%)}
.gs18-HeaderPulldown__Sublist--Overflow .gs18-HeaderPulldown__List{margin:70px 30px 0 0;padding:0;height:calc(100% - 70px);overflow-y:scroll;z-index:1;scrollbar-base-color:#757575}
.gs18-HeaderPulldown__Sublist--Overflow .gs18-HeaderPulldown__ListItem{border-right:#ccc solid 1px}
.gs18-HeaderPulldown__ListItem>a.is-open{width:51%;padding-left:14px;background-color:#e1e1e1;color:#000}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a.is-open{padding-left:16px;padding-right:14px}
.gs18-HeaderPulldown__ListItem>a.is-open::after{right:15px}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a.is-open::after{right:auto;left:15px}
.gs18-HeaderPulldown__ListItem>a.is-open:active,.gs18-HeaderPulldown__ListItem>a.is-open:focus,.gs18-HeaderPulldown__ListItem>a.is-open:hover,.gs18-HeaderPulldown__ListItem>a:active,.gs18-HeaderPulldown__ListItem>a:focus,.gs18-HeaderPulldown__ListItem>a:hover{color:#f50f10}
.gs18-HeaderPulldown__ListItem>a.is-open:active:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a.is-open:focus:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a.is-open:hover:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a:active:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a:focus:not(:only-child)::after,.gs18-HeaderPulldown__ListItem>a:hover:not(:only-child)::after{border-color:#f50f10}
.gs18-HeaderPulldownRegion{max-width:1380px}
.gs18-HeaderPulldownRegion .gs18-TileItem.gs18-u-Size1of3{margin-bottom:0}
.gs18-RegionListWrap{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.gs18-RegionListWrap .gs18-RegionList{-webkit-box-flex:0;-ms-flex:0 0 33.3%;flex:0 0 33.3%;margin-bottom:0}
.gs18-CookieAlert{height:234px}
.gs18-Header--HasUtilityLinks .gs18-CookieAlert{margin-bottom:56px}
.gs18-CookieAlert__Inner{margin:0 auto;padding:0 30px;max-width:1380px;width:100%}
body.gs18-HasCookieAlert{padding-top:234px}
body.gs18-HasCookieAlert.gs18-HasUtilityLinks{padding-top:290px}
.gs18-HeaderNav{width:100%}
.gs18-HeaderCustomUtilityLinks{-ms-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.gs18-HeaderCustomUtilityLinks__Item a{white-space:nowrap}
.gs18-Footer__Inner{padding:0 30px 30px;height:260px;max-width:1380px;margin:0 auto}
.gs18-Footer__Title{margin-top:0;padding-top:60px}
.gs18-Footer__List{width:680px;display:inline-block}
.gs18-Footer__List>li{margin-bottom:0;display:inline-block}
.gs18-Footer__List>li:nth-child(odd){margin-right:60px}
.gs18-Footer__List>li>a{position:relative;color:inherit;font-size:18px;font-weight:500;text-decoration:none;padding-left:22px}
.gs18-Footer__List>li>a::before{content:"";position:absolute;top:50%;left:0;width:9px;height:9px;border-top:2px solid #000;border-left:2px solid #000;-webkit-transform:translateY(-50%) rotate(-225deg);transform:translateY(-50%) rotate(-225deg);font-weight:700}
[dir=rtl] .gs18-Footer__List>li>a::before{right:0;left:auto;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg)}
.gs18-Footer__Links{position:absolute;left:0;bottom:0;box-sizing:border-box;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}
[dir=rtl] .gs18-Footer__Links{right:0;left:auto}
.gs18-Footer__SNS{margin-right:20px;display:inline-block}
[dir=rtl] .gs18-Footer__SNS{margin-right:auto;margin-left:20px}
.gs18-Footer__Legal{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
.gs18-Footer__Copyright{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
.gs18-Footer__ExtentionInner{padding:0 30px 30px}
.gs18-Footer__Inner{padding-bottom:0;height:230px}
.gs18-Footer__Links{margin-right:auto;margin-left:auto;max-width:1440px;position:static}
[data-js-gs18-footer-sns]+.gs18-Footer__Links{margin-top:200px}
[data-js-gs18-footer-sns]+.gs18-Footer__Links--withme,[data-js-gs18-footer-sns]+.gs18-Footer__Links--withoutme{margin-top:0}
.gs18-Footer__Logo{display:none}
}
@media only screen and (max-width:980px){
.gs18-Header__GlobalLink:active::before,.gs18-Header__GlobalLink:focus::before{opacity:0}
.gs18-Header__GlobalLink:active::after,.gs18-Header__GlobalLink:focus::after{opacity:1}
.gs18-Header__GlobalLink.is-open-small{background-color:#000}
.gs18-Header__GlobalLink.is-open-small::before{opacity:0}
/* .gs18-Header__GlobalLink.is-open-small::after{opacity:1;background-color:#000;background-image:url(../img/icon/Global-W.svg)} */
.gs18-Header{width:100%;padding:0}
.gs18-Header.has-no-searchbox .gs18-Header__Search{display:none}
.gs18-Header.has-no-searchbox .gs18-HeaderNav{top:65px}
.gs18-Header--HasCookieAlert.gs18-Header--HasUtilityLinks.is-flyout.is-hidden,.gs18-Header--HasCookieAlert.is-flyout.is-hidden{top:-365px}
.gs18-Header__Inner{height:65px}
.gs18-Header__Logo{padding-right:13px;border-right:0}
[dir=rtl] .gs18-Header__Logo{padding-right:16px;padding-left:13px;border-left:0}
.gs18-Header__Lang{margin-right:auto;margin-left:0}
[dir=rtl] .gs18-Header__Lang{margin-right:0;margin-left:auto}
.gs18-Header__Global{padding-left:0}
[dir=rtl] .gs18-Header__Global{padding-right:0}
.gs18-Header__GlobalLink{width:48px}
.gs18-Header__GlobalLink:active::after,.gs18-Header__GlobalLink:focus::after,.gs18-Header__GlobalLink:hover::after{opacity:1}
.gs18-Header__Hamburger{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10;width:48px;height:100%;text-align:center;border-color:#e1e1e1;border-width:0 0 0 1px;border-style:solid}
[dir=rtl] .gs18-Header__Hamburger{border-right-width:1px;border-left-width:0}
/* .gs18-Header__Hamburger.is-open-small{background:url(../img/icon/Close.svg) no-repeat center;background-size:20px auto} */
.gs18-Header__Hamburger.is-open-small>img{display:none}
.gs18-Header__Search{transition:.5s width;margin:0;position:absolute;top:65px;right:0;height:auto;display:block;width:0;overflow:hidden;background-color:#fff;border-top:1px solid #e1e1e1;border-bottom:1px solid #e1e1e1;z-index:6}
[dir=rtl] .gs18-Header__Search{right:auto;left:0}
.gs18-Header--HasCookieAlert .gs18-Header__Search{top:365px}
.gs18-Header__SearchInner{box-sizing:border-box;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;height:96px;width:100vw;padding:0 20px 0 16px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center}
.gs18-Header__Search .gs18-Header__SearchInput{position:static;width:100%}
.gs18-Header__Search .gs18-Header__SearchInput .gs18-SearchBox__QueryInput,.gs18-Header__Search .gs18-Header__SearchInput .mf_finder_searchBox_query_input{display:block;padding:0;width:100%;height:35px;border-radius:0}
.gs18-Header__Search .gs18-Header__SearchBtn{display:none}
.gs18-Header__Search .gs18-SearchBox__QueryWrap,.gs18-Header__Search .mf_finder_searchBox_query_wrap{-ms-flex-preferred-size:93%;flex-basis:93%}
.gs18-Header__Search .gs18-SearchBox__Submit,.gs18-Header__Search .mf_finder_searchBox_submit{background-position:-100px -100px;-ms-flex-preferred-size:28px;flex-basis:28px}
.gs18-Header__Search .gs18-SearchBox__Submit:active>span,.gs18-Header__Search .gs18-SearchBox__Submit:focus>span,.gs18-Header__Search .gs18-SearchBox__Submit:hover>span,.gs18-Header__Search .mf_finder_searchBox_submit:active>span,.gs18-Header__Search .mf_finder_searchBox_submit:focus>span,.gs18-Header__Search .mf_finder_searchBox_submit:hover>span{opacity:1}
.gs18-Header__Search.is-open-small{width:100%;overflow:visible}
.gs18-Header--NoSearch .gs18-HeaderNav{top:65px}
.gs18-Header--NoSearch.gs18-Header--HasCookieAlert .gs18-HeaderNav{top:365px}
.gs18-HeaderLang .gs18-HeaderLang__List{position:absolute;display:none;width:100%;margin:0;padding:0;right:0;overflow:hidden;background:#000;list-style:none;font-size:18px;font-weight:500}
.gs18-HeaderLang .gs18-HeaderLang__List>li{border-top:solid 1px #272727}
.gs18-HeaderLang .gs18-HeaderLang__List>li>a{display:block;padding:15px;color:#fff;text-decoration:none}
.gs18-HeaderLang .gs18-HeaderLang__List>li>a:active,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:focus,.gs18-HeaderLang .gs18-HeaderLang__List>li>a:hover{color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Lang{display:block;box-sizing:border-box;padding:24px 14px 22px 0;width:59px;height:65px;text-align:center;font-size:14px;font-weight:500;color:#000;text-decoration:none;position:relative}
.gs18-HeaderLang .gs18-HeaderLang__Lang:before{content:'';display:block;position:absolute;top:29px;right:14px;width:0;height:0;border-style:solid;border-width:7px 4px 0 4px;border-color:#000 transparent transparent transparent}
.gs18-HeaderLang .gs18-HeaderLang__Lang:active,.gs18-HeaderLang .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Lang:active:before,.gs18-HeaderLang .gs18-HeaderLang__Lang:focus:before,.gs18-HeaderLang .gs18-HeaderLang__Lang:hover:before{border-top-color:#f50f10}
.gs18-HeaderLang .gs18-HeaderLang__Lang>abbr{text-decoration:none}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang{background:#000;color:#fff}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang::before{-webkit-transform:rotate(180deg);transform:rotate(180deg);border-top-color:#fff}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang:active,.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang:focus,.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang:hover{color:#f50f10}
.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang:active:before,.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang:focus:before,.gs18-HeaderLang.is-open .gs18-HeaderLang__Lang:hover:before{border-top-color:#f50f10}
.gs18-HeaderLang.is-open .gs18-HeaderLang__List{display:block}
.gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang{display:none}
.gs18-HeaderLang__Stage--Double .gs18-HeaderLang__Lang.gs18-HeaderLang__Lang-Current{display:block}
.gs18-HeaderNav{transition:.5s width;position:absolute;right:0;top:161px;width:0;height:100vh;overflow:hidden;z-index:5}
[dir=rtl] .gs18-HeaderNav{left:0;right:auto}
.gs18-Header--HasCookieAlert .gs18-HeaderNav{top:461px}
.gs18-HeaderNav.is-open-small{width:100%;height:780px;pointer-events:none}
.gs18-HeaderNav.is-open-small>*{pointer-events:all}
.gs18-HeaderNav__Menu{display:block;position:relative;padding:0;left:0;transition:.5s left;border-top:1px solid #e1e1e1;width:100%;height:auto}
.gs18-HeaderNav__Menu.is-step0{left:0}
.gs18-HeaderNav__Menu.is-step1{left:-100%}
.gs18-HeaderNav__Menu.is-step2{left:-200%}
[dir=rtl] .gs18-HeaderNav__Menu{left:auto;right:0;transition:.5s right}
[dir=rtl] .gs18-HeaderNav__Menu.is-step0{left:auto;right:0}
[dir=rtl] .gs18-HeaderNav__Menu.is-step1{left:auto;right:-100%}
[dir=rtl] .gs18-HeaderNav__Menu.is-step2{left:auto;right:-200%}
.gs18-HeaderNav__Item{background-color:#fff;border-bottom:1px solid #e1e1e1}
.gs18-HeaderNav__Text{position:relative;font-size:24px;min-height:85px;box-sizing:border-box;width:100vw}
.gs18-HeaderNav__Text,.gs18-HeaderNav__Text _:-ms-lang(x)::-ms-backdrop{padding-top:30px;box-sizing:border-box}
.gs18-HeaderNav__Text::before{content:"";position:absolute;top:50%;right:20px;width:6px;height:6px;border-top:1px solid #000;border-left:1px solid #000;-webkit-transform:translateY(-50%) rotate(-225deg);transform:translateY(-50%) rotate(-225deg)}
[dir=rtl] .gs18-HeaderNav__Text::before{right:auto;left:20px;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg)}
.gs18-HeaderNav__Item--Careers .gs18-HeaderNav__Text::before{display:none}
.gs18-Header--MinInteraction .gs18-HeaderNav__Text::before{display:none}
.gs18-HeaderNav__Text:active,.gs18-HeaderNav__Text:focus,.gs18-HeaderNav__Text:hover{color:#000}
.gs18-HeaderNav__Text.is-current,.gs18-HeaderNav__Text.is-open{background-color:#000;color:#fff}
.gs18-HeaderNav__Text.is-current::before,.gs18-HeaderNav__Text.is-open::before{border-color:#fff}
.gs18-HeaderNav__Text.is-current:active,.gs18-HeaderNav__Text.is-current:focus,.gs18-HeaderNav__Text.is-current:hover,.gs18-HeaderNav__Text.is-open:active,.gs18-HeaderNav__Text.is-open:focus,.gs18-HeaderNav__Text.is-open:hover{color:#fff}
.gs18-HeaderNav__Text.is-current:active::before,.gs18-HeaderNav__Text.is-current:focus::before,.gs18-HeaderNav__Text.is-current:hover::before,.gs18-HeaderNav__Text.is-open:active::before,.gs18-HeaderNav__Text.is-open:focus::before,.gs18-HeaderNav__Text.is-open:hover::before{border-color:#fff}
.gs18-HeaderNavUtilityLinks{background:#e0e0e0;font-size:18px;line-height:1.45}
.gs18-HeaderNavUtilityLinks__Inner{list-style:none;padding:17px 0 18px;width:100vw}
.gs18-HeaderNavUtilityLinks__Item{padding:8px 15px 7px}
.gs18-HeaderPulldown{display:none;width:100%;left:100%;top:0;overflow:inherit;background-color:#fff}
[dir=rtl] .gs18-HeaderPulldown{right:100%;left:auto}
.gs18-HeaderPulldown.is-current-small{display:block;z-index:1}
.gs18-HeaderPulldown--Region{top:65px;left:auto;right:0;width:0;height:0;border-top:1px solid #e1e1e1;overflow:hidden;transition:width .5s,height .5s,visibility .5s;display:block;visibility:hidden}
[dir=rtl] .gs18-HeaderPulldown--Region{right:auto;left:0}
.gs18-HeaderPulldown--Region.is-transition-small{height:auto}
.gs18-HeaderPulldown--Region.is-open-small{width:100%;height:auto;visibility:visible}
.gs18-HeaderPulldown--Region .gs18-HeaderPulldown__Inner{width:100%;position:relative}
.gs18-HeaderPulldown__Inner{min-height:0}
.gs18-HeaderPulldown__Header{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-wrap:wrap;flex-wrap:wrap;color:#fff;width:100vw;min-height:120px;padding:15px 15px 5px;background-color:#000}
[dir=rtl] .gs18-HeaderPulldown__Header{padding-right:15px;padding-left:15px}
.gs18-HeaderPulldown__Title{width:100%;margin-bottom:0;font-size:32px}
.gs18-HeaderPulldown__Title>a:active,.gs18-HeaderPulldown__Title>a:focus,.gs18-HeaderPulldown__Title>a:hover,.gs18-HeaderPulldown__Title>a:link,.gs18-HeaderPulldown__Title>a:visited{color:#fff;text-decoration:underline}
.gs18-HeaderPulldown__Gotop{display:none}
.gs18-HeaderPulldown__Back{display:block;width:100%;margin-top:10px;margin-bottom:10px;padding-left:16px;color:#fff;font-size:16px;font-weight:500;text-decoration:none;position:relative}
[dir=rtl] .gs18-HeaderPulldown__Back{padding-right:16px;padding-left:0}
.gs18-HeaderPulldown__Back:link,.gs18-HeaderPulldown__Back:visited{color:#fff}
.gs18-HeaderPulldown__Back::before{content:"";position:absolute;top:50%;left:0;width:6px;height:6px;border-top:1px solid #fff;border-left:1px solid #fff;-webkit-transform:translateY(-50%) rotate(-45deg);transform:translateY(-50%) rotate(-45deg)}
[dir=rtl] .gs18-HeaderPulldown__Back::before{right:0;left:auto;-webkit-transform:translateY(-50%) rotate(-225deg);transform:translateY(-50%) rotate(-225deg)}
.gs18-HeaderPulldown__Close{display:none}
.gs18-HeaderPulldown__List{display:block;position:static;padding:0}
.gs18-HeaderPulldown__ListItem{font-size:16px}
.gs18-HeaderPulldown__ListItem>a,.gs18-HeaderPulldown__ListItem>span{display:block;width:100%;padding:1em 15px;border-bottom:1px solid #e1e1e1}
.gs18-HeaderPulldown__ListItem>a:not(:only-child){padding-right:30px}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a:not(:only-child){padding-right:16px;padding-left:30px}
.gs18-HeaderPulldown__ListItem>a:not(:only-child)::after{right:19px;width:6px;height:6px;border-top:1px solid #000;border-left:1px solid #000}
[dir=rtl] .gs18-HeaderPulldown__ListItem>a:not(:only-child)::after{right:auto;left:19px}
.gs18-HeaderPulldown__ListItem>a:hover{width:100%;background-color:#fff}
.gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List .gs18-HeaderPulldown__List .gs18-HeaderPulldown__ListItem a:active,.gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List .gs18-HeaderPulldown__List .gs18-HeaderPulldown__ListItem a:focus,.gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List .gs18-HeaderPulldown__List .gs18-HeaderPulldown__ListItem a:hover{background:#f6f6f6}
.gs18-HeaderPulldown__Sublist{display:none;right:auto;left:0;width:100%;height:auto;min-height:0;padding:0;background-color:#fff}
[dir=rtl] .gs18-HeaderPulldown__Sublist{padding:0;right:0;left:auto}
.gs18-HeaderPulldown__Sublist.is-current-small{display:block}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__Header{display:-webkit-box;display:-ms-flexbox;display:flex}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem>span{padding-top:15px;border-bottom:0}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List{padding:10px 0 10px 28px;background-color:#f6f6f6}
[dir=rtl] .gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List{padding-right:28px;padding-left:0}
.gs18-HeaderPulldown__Sublist .gs18-HeaderPulldown__ListItem .gs18-HeaderPulldown__List .gs18-HeaderPulldown__ListItem>a{padding:.75em 15px;border-bottom:0}
.gs18-HeaderPulldownRegion{padding:60px 0 25px;background-color:#fff}
.gs18-HeaderPulldownRegion .gs18-HeaderPulldown__Close{display:block}
.gs18-HeaderPulldownRegion__Header{margin:0 15px 60px}
.gs18-HeaderPulldownRegion__Title{margin:0;font-size:36px;font-weight:700;line-height:1}
.gs18-HeaderPulldownRegion .gs18-TileItem{margin-bottom:50px}
.gs18-HeaderPulldownRegion .gs18-HeadingLv2{font-size:18px}
.gs18-HeaderPulldownRegion .gs18-LineTable{font-size:16px}
.gs18-HeaderPulldownRegion .gs18-LineTableBodyTh{width:55%}
.gs18-HeaderPulldownRegion .gs18-LinkListItem{margin-top:5px;word-break:break-word}
.gs18-RegionList{margin:0 0 50px;padding:0 15px}
.gs18-RegionList__Title{font-size:18px}
.gs18-RegionList__Country{min-width:inherit;-webkit-box-flex:0;-ms-flex:0 0 55%;flex:0 0 55%;font-size:16px}
.gs18-RegionList__Language>li{width:100%;font-size:16px}
.gs18-RegionList__Language>li>a{padding-left:24px}
[dir=rtl] .gs18-RegionList__Language>li>a{padding-right:24px;padding-left:0}
.gs18-RegionList__Language>li>a::before{margin-right:10px}
[dir=rtl] .gs18-RegionList__Language>li>a::before{margin-right:auto;margin-left:10px}
.gs18-CookieAlert{height:300px}
.gs18-CookieAlert__Inner{padding:0 15px}
body.gs18-HasCookieAlert{padding-top:300px}
.gs18-Header--NoSearch .gs18-HeaderNav{top:65px}
.gs18-HeaderCustomUtilityLinks{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}
.gs18-Footer{box-sizing:border-box;height:auto;padding:30px 15px;width:100%}
.gs18-Footer__Global{margin:-30px -15px 30px;padding:1.75em 15px;font-size:16px;text-align:left}
.gs18-Footer__Global>a{padding-bottom:3px}
.gs18-Footer__Title{margin-top:0;padding-top:10px}
.gs18-Footer__List{width:100%}
.gs18-Footer__List>li{width:100%;margin-bottom:18px;display:block}
.gs18-Footer__List>li>a{font-size:16px}
.gs18-Footer__Links{margin-top:54px;}
/* matt rwd */
/* .gs18-Footer__Links{margin-top:54px;padding:0} */
.gs18-Footer__Legal{font-size:16px}
.gs18-Footer__SNS>li:not(:last-child){margin-right:20px}
[dir=rtl] .gs18-Footer__SNS>li:not(:last-child){margin-right:auto;margin-left:20px}
.gs18-Footer__Copyright{font-size:16px}
.gs18-Footer__ExtentionInner{padding:30px 0 0;font-size:16px}
.gs18-Footer__Inner{margin-bottom:54px}
.gs18-Footer__Links{margin-top:0}
[data-js-gs18-footer-sns]+.gs18-Footer__Links{margin-top:192px}
[data-js-gs18-footer-sns]+.gs18-Footer__Links--withme,[data-js-gs18-footer-sns]+.gs18-Footer__Links--withoutme{margin-top:0}
.gs18-Footer__LogoInner{margin:30px -15px -30px;padding:15px 15px 63px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;background-color:#fff}
.gs18-Footer__LogoInner .gs18-Footer__Logo{margin:10px 0;-webkit-box-flex:2;-ms-flex-positive:2;flex-grow:2}
.gs18-Footer__LogoInner .gs18-Footer__Logo img{width:auto;height:29px}
.gs18-Footer__LogoInner .gs18-Footer__Logo--ME{margin:13px 0;-webkit-box-flex:0;-ms-flex-positive:0;flex-grow:0}
.gs18-Footer__LogoInner .gs18-Footer__Logo--ME img{width:auto;height:24px}
}
@media only screen and (min-width:768px) and (max-width:1280px){
.gs18-HeaderPulldownRegion col{width:50%}
}
@media (max-width:1280px){
.gs18-RegionList__Language>li{width:100%}
}
@media only screen and (min-width:981px) and (max-width:1100px){
.gs18-HeaderNav__Text{font-size:15px}
.gs18-HeaderNavUtilityLinks__Item{font-size:15px;margin-left:15px}
[dir=rtl] .gs18-HeaderNavUtilityLinks__Item{margin-left:0;margin-right:15px}
}
@media only screen and (max-width:980px) and (max-width:580px){
.gs18-Header__Search .gs18-SearchBox__QueryWrap,.gs18-Header__Search .mf_finder_searchBox_query_wrap{-ms-flex-preferred-size:87%;flex-basis:87%}
}
@media only screen and (max-width:980px) and (max-width:360px){
.gs18-HeaderLang .gs18-HeaderLang__Lang{padding-right:10px;width:49px}
.gs18-HeaderLang .gs18-HeaderLang__Lang:before{right:10px}
}
@media only screen and (max-width:980px) and (max-width:980px){
.gs18-HeaderPulldownRegion .gs18-Tile{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column}
.gs18-HeaderPulldownRegion .gs18-TileItem{display:block}
.gs18-HeaderPulldownRegion .gs18-TileItem.gs18-u-Size1of3{width:100%}
.gs18-HeaderPulldownRegion .gs18-TileItem__Body,.gs18-HeaderPulldownRegion .gs18-TileItem__Foot,.gs18-HeaderPulldownRegion .gs18-TileItem__Head{padding-right:15px;padding-left:15px}
}
@media print and (max-width:1100px){
.gs18-HeaderNav__Text{font-size:15px}
.gs18-HeaderNavUtilityLinks__Item{font-size:15px;margin-left:15px}
[dir=rtl] .gs18-HeaderNavUtilityLinks__Item{margin-left:0;margin-right:15px}
}
@media only screen and (max-width:767px){
.gs18-Footer__Legal{font-size:17px}
.gs18-Footer__Copyright{font-size:17px}
.gs18-HeadingLv2{font-size:1.5rem}
.gs18-HeadingLv2--Catch{font-size:2rem}
.gs18-HeadingLv2--Icon .gs18-HeadingLv2__IconCol{width:59.5px}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag{font-size:.75rem}
.gs18-HeadingLv2Unit__SubTextCol{font-size:.88rem}
.gs18-HeadingLv2Unit--Link{display:block}
}
@media only screen and (min-width:768px){
.gs18-HeadingLv2{font-size:1.56rem}
.gs18-HeadingLv2--Catch{font-size:3.56rem}
.gs18-HeadingLv2--Icon .gs18-HeadingLv2__IconCol{width:70px}
.gs18-HeadingLv2--Tag .gs18-HeadingLv2Tag{font-size:.78rem}
.gs18-HeadingLv2Unit__SubTextCol{font-size:.89rem}
.gs18-HeadingLv2Unit--Link{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}
.gs18-HeadingLv2Unit__LinkCol{margin-top:50px;padding-left:20px}
.gs18-Footer__Title{font-size:28px}
}
