.main-content {
  width: $main-width;
  position: absolute;
  right: 0;
  // left:200px;
  padding: 0px 5px 0px 15px;
  overflow-y: auto;
  @include media-breakpoint-down(sm) {
    width: 100vw;
  }
}
// Main content adjustments


.main-content > .main-content-container.container-fluid {
  overflow-y: scroll;
  height: $main-content-withoutfooter;
  @media only screen and (max-width:980px){
    height: $main-content-withoutfooter-slim;
    
  }
  // max-height: calc(100svh) - ($main-navbar-height + $main-footer-height);
  // min-height: calc(100vh);// - #{$main-navbar-height + $main-footer-height});
}
