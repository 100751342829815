
.body-holder { min-height: 100vh; display: block;}
.content-holder { min-width: 1140px; padding: 0px 30px;}

/* 佈景主題相關 */
/* .background { background-image: url("../images/background.png");} */
.text-danger { color: #E74848;}


/* 佈局 */
.top-nav-bar {padding: 0px 15px; height: 59px; display: flex; justify-content: flex-end; align-items: center; box-shadow: 0 0 30px 0 rgba(0,0,0,0.15);}

.side-menu {width:100%; box-shadow: 0 0 30px 0 rgba(0,0,0,0.15); }
.side-menu .logo {height: 36px; width: 128px; padding: 11px 55px;}
.side-menu ul {padding: 0px; margin-top: 0px;}
.side-menu ul li {list-style:none; vertical-align: middle; padding-left: 15px;}
.side-menu ul li a {color:#4d4d4d; text-decoration:none;}
.side-menu ul .section { font-size:14px; height:28px; line-height:28px; border-top: 1px solid #E1E5EB; border-bottom: 1px solid #E1E5EB; }
.side-menu ul .cell {font-size:14px; height:42px; line-height:42px;}

/* 卡片 */
.main-card { background: #FFFFFF; box-shadow: 0 0 30px 0 rgba(0,0,0,0.15); border-radius: 10px; padding: 15px;}
.main-card .header { display: flex;}
.main-card .header .logo { height: 56px; width: 200px; margin: auto;}
.main-card form {display: flex; flex-direction: column;}
.main-card form .input-group{margin:5px 0px; color:#646464;}
.main-card form .input-group label{margin:0px 5px;}
.main-card form .input-group input[type="text"] {border: 1px #E1E5EB solid; border-radius:3px; color:#4d4d4d; height: 35px; width: 340px; }
.main-card .footer {display: flex;}

/* 按鈕 */
.main-btn {background: #50BE00; border-radius: 3px; color:white; font-size: 12px; border-width: 0px; padding: 9px 25px;}
.big-btn {padding: 9px 52px;}


/* 版面位置與大小相關 */
.row{display: flex; flex-wrap: wrap;}
.screen-center{width: 100vw; height: 100vh; display: flex; align-items:center; justify-content:center;}
.center{justify-content: center; align-items: center;}
.justify-content-center{justify-content: center;}
.align-items-center{align-items: center !important;}
.align-self-center{align-self: center;}
.align-self-fend{align-self: flex-end;}

.text-end {text-align: end;}
.text-center {text-align: center;}

.ml-auto{margin-left: auto;}
.mr-auto{margin-right: auto;}
.mt-auto{margin-top: auto;}
.mb-auto{margin-bottom: auto;}

.w-20{width: 20%;}
.w-25{width: 25%;}
.w-33{width: 33%;}
.w-50{width: 50%;}
.w-75{width: 75%;}
.w-100{width: 100%;}

/* 常用字體 */
.pf-m {font-family: "PingFangTC-Medium";}
.pf-r {font-family: "PingFangTC-Regular";}
