.form-input{
    max-height: 38px;
    max-width: 120px;
    font-size: 14px;
}
.form-text-input{
    max-height: 30px;
    max-width: 300px;
    font-size: 14px;
}
.underline-label{
    text-decoration: underline;
    cursor: pointer;
}
.list-checkbox{
    width: 118px;
}

.collapse-btn{
    text-align: start;
    background: #1ab394;
    color: #fff;
    font-size: 16px;
    padding: 8px 10px;
    border-radius: 2px;
    cursor: pointer;
    &:hover, 
    &:focus{
        background: #1ab394;
    }
}