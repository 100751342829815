.tme_copyright {
    margin-right:auto;
    font-size:18px;
    position: absolute;
    text-align: left;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dbdbdb;
    z-index: 7;
  }
.tme-in-copyright {
      margin-right:auto;
      font-size:18px;
      position: fixed;
      text-align: left;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #dbdbdb;
      z-index: 7;
      color: black;
}
.login-news-listitem-text {
   max-height: 3em;
  
   overflow: visible;
   float: left;
}
.login-news-listitem-text-date {
   max-width: 15em;
   margin-right: 1em  !important;
}
.login-news-listitem-text-text {
   max-width: 40em;
   word-wrap: break-word;
   /* border-style:solid;
   border-color: #00FF00;
   border-width: 2px; */
}
.login-news-listitem-text-text:hover {
   font-weight: bolder;
   cursor: pointer;
}
@media only screen and (max-width :890px) {
   .login-news-listitem-text-text {
      max-width: 20em;
      word-wrap: break-word;
    
      /* border-style:solid;
      border-color: #00FF00;
      border-width: 2px; */
   }
 

   .login-ext-card {
      margin-bottom: 7vmax !important;
       }   
   html,body{
      overflow-y: hidden !important;
      }
 }
@media only screen and (max-width :535px) {
.login-news-listitem-text {
   max-height: 3em;
   overflow: visible;
   float: none;
   margin-left: 1.3em !important;
}
.login-ext-card {
   margin-bottom: 7vmax !important;
      }
}
@media only screen and (max-width :325px) {
   .xtme_copyright {

      display: none;
    }
}
.login_news_list_inner{
   width: 100%;
}
.login-news-arrow{
   color:#DD0000;
   float: left;
}
.login-news-listitem {
 margin: 1px;
 padding-top: 0.5em;
 padding-bottom: 0.5em;
}
.login-news-list-box {
    /* max-height: 2em; */
    margin: 10px;
    width: 100%;
    overflow: visible;
    /* border-style:solid;
    border-color: #00FF00;
    border-width: 2px; */
}
.login-news-list-moretext {
   float: right;
   color: #C02F46 !important;
   background-color: white !important;
   margin: 1em;
   min-height: 2em;
    border-style:none  !important;
   /* border-color: #00FF00; */
   border-width: 0px; 
   font-weight: bold !important;
}
.login-news-listitem{
   margin-top: 1em 1em 4em 1em;
}
.login-image {
   margin-top: -60px;
 }
 .login-image-box {
border-radius: 0.625rem 0px 0px 0px; 
   overflow: hidden;
 }
 .login-cardbody{
    padding-bottom: 5px;
 }
 .login-outbox-box {
    overflow: auto;
 }
 /* .login-outbox-card {
    position: relative; 
    top: -50px;

 } */
 .login_news_dialog{
   max-width: 500px;
   
   /* width:80svw; */
   min-height: 60svh;
   margin: auto;
 }
 .login_news_dialog_close{
   position: absolute;
   top: 0.5em;
   right: 1em;
 }
 .login_news_popup_header_date{
   margin: 0px;
   float: left;
   padding: 1em 1em 0.5em 1.4em !important;

 }
 .login_news_popup_header_title{
   padding: 0.2em 1em 0.3em 1.1em !important;
 }
 .login_news_popup_content{
   width: 500px !important;
   max-width: 80%;
   word-wrap: break-word;
 }

 .mainsider-menu{
   /* max-height: 500; */
   /* overflow-y: scroll; */
   /* border-style: solid; */
   border-width: 2px;
   border-color: purple;
 }
 .mainsider-submenu{
   /* border-style: solid;
   border-width: 2px;
   border-color: green; */
   top:0px;
   /* bottom: 50px;
   height: calc(100vh - 165px - 1.5rem )  !important; */
 }
 .overtime_list_summ_box{
   max-width: 250px;
 }
 .leave_list_summ_box{
   max-width: 150px;
   margin-top: 0.5em;
 }
 .overtime_list_summ_row{
   margin: 0;
   padding: 0;
 }
 .overtime_list_summ_box_item1{
   max-width: 250px;
   align-self: center;
 }
 .overtime_list_summ_box_item2{
   margin-left: auto !important;
   /* display: flex; 
   justify-content: flex-end */
 }
/* @media screen {
   body,html {
      overflow-y: auto;
   }
} */
 @media only screen and (max-height:940px) {


    .tme-in-copyright {
      margin-right:auto;
      font-size:18px;
      position: fixed;
      text-align: left;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #dbdbdb;
      /* display: none!important; */
     
    }
 }
 @media only screen and (min-height:800px) {
   /* .mainsider-submenu{
      bottom: unset;
    } */
    
 }
 @media only screen and (max-height:800px) {

    .tme_copyright {
        margin-right:auto;
        font-size:18px;
        text-align: left;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #dbdbdb;
        position: sticky;
     /*   display: none;*/
       
      }
      /* .tme-in-copyright {
         margin-right:auto;
         font-size:18px;
         position: fixed;
         text-align: left;
         left: 0;
         right: 0;
         bottom: 0;
         background-color: #dbdbdb;
         display: none;
        
       } */
    /* .login-outbox-card {
        position: relative;
        overflow-y: scroll !important;
        top: -50px;
     } */
  }
@media only screen and (max-height:750px) {

   body,html {
      overflow-y: auto !important;
   }

 }
@media only screen and (min-height:750px) {

   /* body,html {
      overflow-y: auto !important;
   } */
   .login-ext-card {
      margin-bottom: 7vmax !important;
   }   
 }
/* @media only screen {
   .container-child {
      overflow: auto;
      max-height: calc(100svh - 230px ) !important;
      min-height: calc(100svh - 230px ) !important;
   }
} */
.container-row{
   overflow-y: auto;
   margin-bottom: 50px;
}


 @media print{
   .mindiv{
      height: 99.9%;
      margin: 0px;
      padding: 0px;
      overflow: hidden;
      
   }
   .print-table-width{
      width: 100%;
   }
 }
 /* .print-mar-test{
   border: solid 3px red;
   margin: auto;
 } */
.second_label {
   font-weight: 300;
}

.scroll-nav-wrapper{
   height: calc(100svh - 201px ) !important;
}
@media only screen and (max-height:900px) {
   .scroll-nav-wrapper{
      height: calc(100svh - 201px ) !important;
      /* 90 */
   }
 }
 .login-content{
   height: calc(100vh - 55px );
 }
 @media only screen and (max-width:980px){
   .login-content{
      height: calc(100vh - 55px );
    }
    .scroll-nav-wrapper{
      height: calc(100svh - 201px ) !important;
   }
 }
