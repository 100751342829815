// Main Stylesheet

// Main SDP Styles
@import "../styles/shards-dashboards";

// Variables
@import "./variables";

// Plugin Styles
@import "./dropzone";
@import "./quill";
@import "./range-date-picker";
@import "./file-manager-list";
@import "./react-tagsinput";
@import "./react-big-calendar";
@import "./style";
@import "./react-input-form";

.copyright {
    font-size: 12px;
    text-align: left;
    position: absolute;
    color: $shuttle-gray;
    left: 0;
    right: 0;
    bottom: 0;
  }
